export default {
    data:() => ({
        _cachedFilters: false,
        _defaultFiltersFunction: null,
    }),
    watch:{
        filter:{
            deep:true,
            handler:function(){
                if (this._cachedFilters) {
                    sessionStorage.setItem(this.sessionKey, JSON.stringify(this.filter));
                }
            }
        },
        filters:{
            deep:true,
            handler:function(){
                if (this._cachedFilters) {
                    sessionStorage.setItem(this.sessionKey, JSON.stringify(this.filters));
                }
            }
        }
    },
    created() {
        this.sessionKey = `${this.$route.name.replaceAll(" ", "-")}-filters`;
    },
    methods:{
        loadCachedFilters(defaultFiltersFunction){
            this._cachedFilters = true;
            this._defaultFiltersFunction = defaultFiltersFunction;
            try {
                let cachedFilters = JSON.parse(sessionStorage.getItem(this.sessionKey));
                if (cachedFilters == null) {
                    return defaultFiltersFunction();
                }
                return cachedFilters;
            } catch (e) {
                console.error(e);
                return defaultFiltersFunction();
            }
        },
        resetFilters(){
            if(this.filters != null){
                this.filters = this._defaultFiltersFunction();
            }
            if(this.filter != null){
                this.filter = this._defaultFiltersFunction();
            }
            sessionStorage.removeItem(this.sessionKey);
        }
    }
}
